import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

//import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './app.home.component';
import { ServiceATCComponent } from './app.serviceatc.component';
import {AgenteDigitalizadorComponent} from './app.agentedigitalizador.component'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServiceATCComponent,
    AgenteDigitalizadorComponent
	],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot([
      { path: 'home', component: HomeComponent },
      { path: 'service', component: ServiceATCComponent },
      { path: 'agente-digitalizador', component: AgenteDigitalizadorComponent },
      { path: '**', redirectTo: 'home', pathMatch: 'full' }
    ], {scrollPositionRestoration: 'enabled', useHash:true})
  ],
  providers: [HttpClientModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
