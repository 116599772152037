import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

	constructor() {
        this.loadScripts();
	}
	loadScripts() {
		const dynamicScripts = [
			 'assets/js/jquery-1.11.1.min.js',
			 'assets/js/owl.carousel.min.js',
			 'assets/js/bootstrap.min.js',
			 'assets/js/wow.min.js',
			 'assets/js/jquery.onepagenav.js',
			 'assets/js/main.js',
			 'assets/js/jquery.waypoints.min.js',
			 'assets/js/jquery.cslider.js',
			 'assets/js/start.cslider.js',
			 'assets/js/expand.js'
		];
		const dynamicStyles = [
			'assets/css/strip.css'
		];
		for (let i = 0; i < dynamicScripts.length; i++) {
			const node = document.createElement('script');
			node.src = dynamicScripts[i];
			node.type = 'text/javascript';
			node.async = false;
			node.charset = 'utf-8';
			document.getElementsByTagName('head')[0].appendChild(node);
		}
		for (let i = 0; i < dynamicStyles.length; i++) {
			const node = document.createElement('link');
			node.href = dynamicStyles[i];
			node.type = 'text/css';
			node.rel = 'stylesheet';
			document.getElementsByTagName('head')[0].appendChild(node);
		}
	}
	
}
