import { Component } from '@angular/core';
import { DataService } from '../app/data.service';

@Component({ 
  templateUrl: '../pages/home.html'
})
export class HomeComponent {

    public clientData: object; 

	constructor(private dataService : DataService) {
        this.dataService.getC3ClientsData().subscribe(data => this.clientData = data);
	}
	// loadScripts() {
	// 	const dynamicScripts = [
	// 		 'assets/js/jquery-1.11.1.min.js',
	// 		 'assets/js/owl.carousel.min.js',
	// 		 'assets/js/bootstrap.min.js',
	// 		 'assets/js/wow.min.js',
	// 		 'assets/js/jquery.onepagenav.js',
	// 		 'assets/js/main.js',
	// 		 'assets/js/jquery.waypoints.min.js',
	// 		 'assets/js/jquery.cslider.js',
	// 		 'assets/js/start.cslider.js',
	// 		 'assets/js/expand.js'
	// 	];
	// 	const dynamicStyles = [
	// 		'assets/css/strip.css'
	// 	];
	// 	for (let i = 0; i < dynamicScripts.length; i++) {
	// 		const node = document.createElement('script');
	// 		node.src = dynamicScripts[i];
	// 		node.type = 'text/javascript';
	// 		node.async = false;
	// 		node.charset = 'utf-8';
	// 		document.getElementsByTagName('head')[0].appendChild(node);
	// 	}
	// 	for (let i = 0; i < dynamicStyles.length; i++) {
	// 		const node = document.createElement('link');
	// 		node.href = dynamicStyles[i];
	// 		node.type = 'text/css';
	// 		node.rel = 'stylesheet';
	// 		document.getElementsByTagName('head')[0].appendChild(node);
	// 	}
	// }

}
