import { Component,OnInit, AfterViewChecked } from '@angular/core';


@Component({
  templateUrl: '../pages/agente-digitalizador.html'
})
export class AgenteDigitalizadorComponent implements OnInit {
    ngOnInit(){
        if(window['Expand']) window['Expand'].reload();
    }
}
